import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {MODAL_MINER, MODAL_DONATE, ROUTE_HOME} from "../routes";
import useModal from "../hooks/useModal";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { ADD_GOLD, DefaultStateType, REDUCE_CRYSTALS, SET_ACTIVE_MINERS, SET_ENERGY_LEFT, SET_FREEZE, SET_RECHARGING_ENERGY_PER_SECOND, UPDATE_ACTIVE_MINERS, UPDATE_BOOST, getDispatchObject } from '../store/reducer';
import OverflowIcon from '../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../assets/icons/currency/crystal/Base';
import CrystalShadow from '../assets/icons/currency/crystal/Shadow';
import { fetchData } from '../utils/api';
import { boostsFrontToServerId } from '../utils/utils';
import { formatNumberShort, formatNumberWithSpaces } from '../utils/mathUtils';
import { sendAnalytics } from '../utils/gtag';
import dayjs from 'dayjs';

interface ModalParams {
  id: string
  name: string
  description: string
  price: number
}

// @ts-ignore
const tg = window['Telegram'].WebApp;

const MinerModal: FC = () => {

    const { t } = useTranslation();
    const crystals = useSelector((s: DefaultStateType) => s.crystals)
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const [params, setParams] = useState<ModalParams>()
    const dispatch = useDispatch()
    // const navigate = useNavigate()

    useEffect(() => {
      if (!activeModalParams) return
      setParams({
        id: activeModalParams.id,
        name: t(`minerName--${activeModalParams.id}`),
        description: t(`minerDescription`, { count: activeModalParams.total, count_2: (Math.floor(activeModalParams.total * 100 / 1440) / 100).toPrecision(3) }),
        price: activeModalParams.price
      })
    }, [activeModalParams])
    
    const buyMiner = async () => {
      if (!params?.id || !params?.price) return

      const response = await fetchData('/miners/buy', {
        miner: params.id
      })

      if (response.error) return
      const result = response.result

      dispatch(getDispatchObject(UPDATE_ACTIVE_MINERS, {
        type: "ADD",
        id: result.id,
        data: {
          uid: result.id,
          id: result.minerCode,
          expiredAt: dayjs(result.expiredAt).unix(),
          progress: 0,
          status: 'ACTIVE',
          price: result.miner.dimondsPrice,
          total: result.miner.maxCbc
        }
      }))

      sendAnalytics('spend_virtual_currency', {
        event_category: 'miner',
        value: params.price,
        virtual_currency_name: 'crystals',
        item_name: params.id
      })
      
      dispatch(getDispatchObject(REDUCE_CRYSTALS, params.price));
      setActiveModal(null)

    }

    const confirm =() => {
        tg.showPopup({
          title: t('boostsConfirmTitle'),
          message: t('boostsConfirmText'),
          buttons: [
              {
                  id: 'confirm',
                  type: 'default',
                  text: t('boostsConfirmButton')
              },
              {
                  id: 'cancel',
                  type: 'cancel',
                  text: t('boostsConfirmCancelButton')
              },
          ],
      }, (id: string) => {
          if (id === 'confirm') {
            buyMiner();
          }
      });
    }

    return (
        <Modal
            isOpen={params && activeModal === MODAL_MINER}
            placement='bottom'
            scrollBehavior='inside'
            className='bg-[#19192B] relative'
            onClose={() => setActiveModal(null)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader></ModalHeader>
                        <ModalBody className="px-4 gap-6">
                            <div className='flex flex-col items-center justify-center'>
                              <img width={96} height={96} className='object-contain' src={require(`../assets/images/mining/${params?.id ?? 'cbc_miner_200'}.png`)} alt="" />
                              <p className='text-3xl font-bold text-center mt-5'>
                                {(params?.name) ?? t('noTitle')}
                              </p>
                              <p className='text-base/6 text-center mt-1.5'>
                                {(params?.description) ?? t('noDescription')}
                              </p>
                              {
                                (params?.price ?? 0) > (crystals ?? 1) ? 
                                <p className='text-base/6 text-red-600 font-semibold text-center mt-4'>
                                  {t('boostsInsufficientFunds')}
                                </p> : null
                              }
                              <div className='flex w-fit gap-2 items-center mt-4'>
                                <OverflowIcon 
                                  Base={<CrystalBase width='24px' height='36px' />}
                                  Shadow={<CrystalShadow width='51px' height='44px' />}
                                  shadowOffsetTop={22}
                                  shadowOffsetLeft={-14}
                                  width={24}
                                  height={40}
                                />
                                <p className='text-4xl font-bold text-center'>
                                  {formatNumberWithSpaces(params?.price ?? 0)}
                                </p>
                              </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='flex-col'>
                          {
                            (params?.price ?? 0) > (crystals ?? 1) ? 
                              <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 py-4 h-fit text-lg'
                                onPress={() => setActiveModal(MODAL_DONATE)}
                              >
                                {t('boostsBuyCrystals')}
                              </Button> :
                              <Button
                                fullWidth
                                className='bg-[var(--link\_color)] opacity-100 rounded-lg px-3 py-4 h-fit text-lg'
                                onPress={confirm}
                              >
                                {t('boostsGet')}
                              </Button>

                          }
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default MinerModal;