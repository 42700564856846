import React, {FC, useEffect, useMemo, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import './Profile.css'
import Icon24PremiumCrown from '../../assets/icons/Icon24PremiumCrown';
import OverflowIcon from '../../components/OverflowIcon/OverflowIcon';
import CrystalBase from '../../assets/icons/currency/crystal/Base';
import CrystalShadow from '../../assets/icons/currency/crystal/Shadow';
import { getLongTimeLeft, premiumOptions } from '../../utils/utils';
import { Button } from '@nextui-org/react';
import { DefaultStateType, getDispatchObject, REDUCE_CRYSTALS, SET_CURRENT_DAILY, SET_DAILIES, SET_GOLD_PER_HOUR, SET_PREMIUM } from '../../store/reducer';
import useModal from '../../hooks/useModal';
import { MODAL_BUY_CRYSTALS, MODAL_DONATE } from '../../routes';
import { fetchData } from '../../utils/api';
import dayjs from 'dayjs';
import TelegramBackButton from '../../components/TelegramBackButton/TelegramBackButton';

const PremiumOptionCell: FC<{id: string, amount: number, price: number, discount: number | null, original: number | null, active: boolean, setActive: Function}> = (props) => {
  const {t} = useTranslation();

  return (
    <div 
      key={props.id + 'premium'}
      className={
        ['Premium--cellwrap border-solid rounded-2xl relative',
        props.active ? 'border-2 border-[#4F3CC8]' : 'border-2 border-transparent'
        ].join(' ')
      }
      onClick={() => props.setActive()}
    >
      {
        props.discount !== null ?
        <div className="absolute w-full inset-x-0 top-[-12px] flex justify-center items-center">
          <div className='bg-[#16C784] px-2 text-xs tracking-widest font-black text-white uppercase rounded-xl'>
            {t('donateSave')} {props.discount}%
          </div> 
        </div> : null
      }
      <div
        key={props.id + props.amount}
        className={[
          'flex flex-col items-center justify-center py-4 px-2 rounded-xl',
          props.amount === 14 ? 'PentagonBg before:inset-0' : null
        ].join(' ')}
      >
        <div className='flex flex-col items-center gap-1'>
          <p className='text-lg font-bold'>
            {props.amount} {t(props.amount === 3 ? 'day3' : 'days')}
          </p>
          <div className='flex gap-1'>
            <OverflowIcon
              Base={<CrystalBase width='12px' height='20px' />}
              Shadow={<CrystalShadow width='26px' height='23px' />}
              shadowOffsetTop={11}
              shadowOffsetLeft={-7}
              width={12}
              height={20}
            />
            <p className='text-xl/6 font-black text-[var(--link\_color)]'>
              {props.price}
            </p>
          </div>
          {
            props.original !== null ? 
            <p className='text-sm w-fit px-1 text-[#AAAAAA] strike-through'>
              {props.original}
            </p> : 
            <div className='h-3 w-full'/>
          }
        </div>
      </div>
    </div>
  )
}

const Premium: FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const crystals = useSelector((s: DefaultStateType) => s.crystals)
    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const premium = useSelector((s: DefaultStateType) => s.premium)
    const [timeLeft, setTimeLeft] = useState<string | null>(premium && premium.active ? getLongTimeLeft(premium.until, t('dayShort')) : null)

    const [selected, setSelected] = useState('seven')

    const buyPremium = async (price: number, id: string) => {
      if (crystals === null) return
      if (crystals < price) {
        setActiveModal(MODAL_DONATE)
      }
      else {
        const response = await fetchData('/premium/buy', { pack: id } )
        if (response.error) return

        const result = response.result
        dispatch(getDispatchObject(SET_GOLD_PER_HOUR, result.goldPerSecond * 3600));
        dispatch(getDispatchObject(SET_PREMIUM, { active: result['isPremium'], until: result['premiumExpired'] ? dayjs(result['premiumExpired']).toDate() : dayjs().toDate() }))
        dispatch(getDispatchObject(REDUCE_CRYSTALS, price));
        await fetchDailies()
      }
    }

    const fetchDailies = async () => {
      const response = await fetchData('/user/get_daily_rewards')
      if (response.error) return
      if (!response.result) {
          return
      }
      const result = response.result.list.map((item: any) => {
          return {
              rewardLevel: item.rewardLevel,
              profit: item.goldAmount,
              unlockedAt: dayjs(item.unlockedAt).unix(),
              status: item.status
          }
      })

      dispatch(getDispatchObject(SET_DAILIES, result.sort((a: any, b: any) => a.rewardLevel - b.rewardLevel).reduce((res: any, item: any) => ({ ...res, [item.rewardLevel]: item}), {})))


      const now = dayjs().unix()
      const next = dayjs(response.result.currentTask.unlockedAt).unix()
      const reset = response.result.isReset
      const curr = {
          profit: response.result.currentTask.goldAmount,
          unlockedAt: reset ? (now + 24 * 60 * 60 - 1) : (next + (now < next ? 0 : 24 * 60 * 60)),
          available: reset ? true : (now > next ? true : false),
          rewardLevel: response.result.currentTask.rewardLevel
      }

      dispatch(getDispatchObject(SET_CURRENT_DAILY, curr))
  }

    useEffect(() => {

      const interval = setInterval(() => {

      if (!premium || !premium.active) {
        if (timeLeft !== null) setTimeLeft(null)
        return
      }
      setTimeLeft(getLongTimeLeft(premium.until, t('dayShort')))

    }, 1000);

    return () => clearInterval(interval);

    }, [timeLeft, premium])

    return (
        <Panel scrollable={false}>
          <TelegramBackButton />
          
          <div className='Premium--page flex mt-1 flex-col w-full justify-center items-center relative bg-transparent'>

            <div className='w-16 h-16 self-center'>
                <img className='w-full object-contain' src={require('../../assets/images/preload/crown.png')} alt="" />
            </div>
            <div className='text-[28px]/9 text-white font-bold text-center'>
              {t('premiumStatus')}
            </div>
            <div className='text-sm text-white font-normal mt-1 px-4 text-center'>
              {t('premiumPageDesc')}
            </div>

            {
              premium && premium.active ? <div className="bg-[#EA3943] px-1.5 mt-2 text-base font-semibold rounded-xl tracking-wider">
                {timeLeft}
              </div> : null
            }

            <div className='flex flex-col w-full gap-3 justify-start items-start mt-4'>
              <div className='flex gap-3'>
                <Icon24PremiumCrown />
                <p className='text-base font-normal text-wrap text-left'>
                  {t('premiumOffline')}
                </p>
              </div>
              <div className='flex gap-3'>
                <Icon24PremiumCrown />
                <p className='text-base font-normal text-wrap text-left'>
                  {t('premiumProfit')}
                </p>
              </div>
              <div className='flex gap-3'>
                <Icon24PremiumCrown />
                <p className='text-base font-normal text-wrap text-left'>
                  {t('premiumDaily')}
                </p>
              </div>
              {/* <div className='flex gap-3'>
                <Icon24PremiumCrown />
                <p className='text-base font-normal text-wrap text-left'>
                  {t('premiumLootbox')}
                </p>
              </div> */}
            </div>
            <div className='absolute Premium--ellipse z-0 inset-0'/ >
          </div>  

          <div className='flex flex-col gap-5 w-full'>
            <div className='text-lg text-white font-normal text-center'>
              {t('premiumChoosePlan')}
            </div>
            <div className='grid grid-cols-3 gap-2'>
              {
                premiumOptions.map((item) => PremiumOptionCell({
                  ...item,
                  active: item.id === selected,
                  setActive: () => setSelected(item.id)
                }))
              }
            </div>
            <Button 
                className='bg-[var(--link\_color)] w-full h-fit rounded-lg py-3 flex gap-2.5 items-center justify-center'
                onClick={() => buyPremium(premiumOptions.find(item => item.id === selected)?.price ?? 0, selected)}
            >
                <div className='font-semibold text-lg'>
                    {t('pay')}
                </div>
            </Button>
          </div>
        </Panel>
    );
};

export default Premium;