import React, {FC, useEffect, useMemo} from 'react';
import Panel from "../../components/Panel/Panel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType, SET_RATE, SET_STAGE, getDispatchObject} from "../../store/reducer";
import {useNavigate} from "react-router-dom";
import Icon24ChevronRight from '../../assets/icons/Icon24ChevronRight';
import { Button } from '@nextui-org/react';
import useModal from '../../hooks/useModal';
import { formatNumberWithSpacesShort } from "../../utils/mathUtils";
import { fetchData } from '../../utils/api';
import TapAchievement from '../../assets/icons/achievements/Tap';
import AchievementCell from './components/Cell/Cell';
import TelegramBackButton from '../../components/TelegramBackButton/TelegramBackButton';

const Achievements: FC = () => {

    const navigate = useNavigate();

    const selector = useSelector((s: DefaultStateType) => s)
    const {t} = useTranslation();
    const {activeModal, setActiveModal, activeModalParams} = useModal();

    const achievements = useSelector((s: DefaultStateType) => s.achievementsList)

    const allBuildings = useMemo(() => {
      return { ...selector.buildings, ...selector.premiumBuildings, ...selector.limitedBuildings }
    }, [selector.buildings, selector.premiumBuildings, selector.limitedBuildings])

    return (
        <Panel scrollable={true}>
            <TelegramBackButton />
            
            <div className='flex flex-col w-full mt-2'>
                <p className='w-full text-white font-bold text-3xl text-center'>
                  {t('myAchievements')}
                </p>
                <p className='mt-1.5 text-sm w-full text-center text-white font-normal'>
                  {t('everyAchievement')}
                </p>
                <div className='mt-8 flex flex-row justify-between items-center'>
                  <div className='flex flex-nowrap w-fit text-base items-center'>
                    <div className='text-white font-normal'>
                      {t('unlocked')}
                    </div>
                    <div className='ml-1.5 text-green-400 font-bold'>
                      {Object.values(achievements ?? []).filter(item => item.unlocked).length}
                    </div>
                    <div className='text-[var(--secondary\_hint)] font-bold tracking-wide ml-2'>
                      {'/ ' + Object.values(achievements ?? []).length}
                    </div>
                  </div>
                  <div className='text-white text-base font-bold'>
                    + {+((Object.values(achievements ?? []).filter(item => item.unlocked).length * 0.5).toFixed(1))}% / {t('hour')}
                  </div>
                </div>
                <div className='mt-3 grid grid-cols-3 place-items-center gap-y-2'>
                  {
                    achievements ? Object.values(achievements).sort((a, b) => {
                      return (a.unlocked !== b.unlocked) ? (a.unlocked ? -1 : 1) : 0
                    }).map(item => 
                      <AchievementCell 
                        level={item.level}
                        type={item.type}
                        unlocked={item.unlocked}
                        target={item.target}
                        showModal
                        id={item.innerId in allBuildings ? allBuildings[item.innerId].name : 'ATM'}
                      />) : null
                  }
                </div>
            </div>
        </Panel>
    );
};

export default Achievements;