import React, {FC} from 'react';
import {Button, Progress, Skeleton} from "@nextui-org/react";
import './EnergyBar.css';
import IconText from "../../../../components/IconText/IconText";
import {useSelector} from "react-redux";
import {DefaultStateType} from "../../../../store/reducer";
import {formatNumberShort, formatNumberWithSpaces} from "../../../../utils/mathUtils";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTE_BOOSTS, ROUTE_MINING} from "../../../../routes";
import ProfitCells from '../../../General/LeagueProgress/components/ProfitCells/ProfitCells';

interface EnergyBarProps {

}

const EnergyBar: FC<EnergyBarProps> = ({}) => {

    const energyLeft = useSelector((selector: DefaultStateType) => selector.energyLeft);
    const dailyEnergy = useSelector((selector: DefaultStateType) => selector.dailyEnergy);
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="EnergyInfo--container">
            {(energyLeft === null || dailyEnergy === null) ? (
                <Skeleton
                    style={{
                        height: 24,
                        width: 300,
                        borderRadius: 16,
                    }}
                />
            ) : (
                <div className="EnergyInfo--container flex justify-between place-items-center w-full">
                    <IconText
                        size="medium"
                        sizeByHeight={true}
                        imgPath={require('../../../../assets/images/extras/lightning.png')}
                        text={`${energyLeft} / ${dailyEnergy}`}
                    />
                    <div 
                        className='EnergyInfo--boost px-3 py-1 rounded-lg'
                        onClick={() => navigate(ROUTE_BOOSTS)}
                    >
                        <IconText
                            size="medium"
                            sizeByHeight={true}
                            imgPath={require('../../../../assets/images/extras/rocket.png')}
                            text={t('navbarBoost')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EnergyBar;