import React, {FC, HTMLAttributes, useEffect, useState} from 'react';
import Panel from "../../components/Panel/Panel";
import TelegramBackButton from "../../components/TelegramBackButton/TelegramBackButton";
import {useDispatch, useSelector} from "react-redux";
import {DefaultStateType, SET_FREEZE, UPDATE_ACTIVE_MINERS, getDispatchObject} from "../../store/reducer";
import { t } from 'i18next';

import useModal from '../../hooks/useModal';
import { MODAL_BOOST_NORMAL, MODAL_BOOST_PREMIUM, MODAL_BUY_BUILDINGS, MODAL_DONATE, MODAL_MINER } from '../../routes';
import { getTimeLeft } from '../../utils/utils';
import ProfitCells from '../General/LeagueProgress/components/ProfitCells/ProfitCells';
import MiningItem from './components/MiningItem/MiningItem';
import dayjs from 'dayjs';

const Mining: FC = ({}) => {

    const {activeModal, setActiveModal, activeModalParams} = useModal();
    const gold = useSelector((selector: DefaultStateType) => selector.gold)
    const crystals = useSelector((selector: DefaultStateType) => selector.crystals)
    const miners = useSelector((selector: DefaultStateType) => selector.miners)
    const activeMiners = useSelector((selector: DefaultStateType) => selector.activeMiners)

    const dispatch = useDispatch()

    const [timeLeftObj, setTimeLeftObj] = useState<{[key: string]: string}>(() => {
      if (!activeMiners) return {}
      const miners = {} as { [key: string]: string }
      for (const key in activeMiners) {
        if (activeMiners[key].status === "ACTIVE") {
          miners[key] = getTimeLeft(activeMiners[key].expiredAt, 'hours')
        }
      }
      return miners
    })

    useEffect(() => {
        if (!activeMiners) return
    
        const interval = setInterval(() => {
          const times = { ...timeLeftObj }
          Object.keys(activeMiners).forEach((key) => {
            const minerTime = activeMiners[key].expiredAt

            if (minerTime > dayjs().unix()) {
              times[key] = getTimeLeft(activeMiners[key].expiredAt, 'hours')
            } else {
              dispatch(getDispatchObject(UPDATE_ACTIVE_MINERS, {
                type: 'PROGRESS',
                id: key,
                data: [{ ...activeMiners[key], status: 'INACTIVE', progress: activeMiners[key].total }]
              }))
            }

            times[key] = getTimeLeft(activeMiners[key].expiredAt, 'hours')
            if (times[key] === '0:00:00') {
              dispatch(getDispatchObject(UPDATE_ACTIVE_MINERS, {
                type: 'PROGRESS',
                id: key,
                data: [{ ...activeMiners[key], status: 'INACTIVE', progress: activeMiners[key].total }]
              }))
            }
          })
          setTimeLeftObj(times)

        }, 1000);
    
        return () => clearInterval(interval);
    }, [timeLeftObj, activeMiners]);

    const openBoostTab = (item: { [key: string]: any }) => {
        if (!crystals) return
        if (item.price > crystals) {
          setActiveModal(MODAL_DONATE)
        } else {
          setActiveModal(MODAL_MINER, {
            id: item.id,
            price: item.price,
            total: item.total
          })
        }
    }

    return (
        <Panel scrollable={true} spaceBetween={false}>
            <TelegramBackButton />
            <ProfitCells />
            <p className='text-[var(--secondary\_hint)] tracking-wider text-sm font-bold uppercase mb-3'>
                {t('minerTitle')}
            </p>
            {
                miners ? miners.map(item => 
                    <MiningItem
                        id={item.id}
                        price={item.price}
                        total={item.total}
                        available={crystals && crystals >= item.price ? true : false}
                        running={undefined}
                        onOpenEvent={() => openBoostTab(item)}
                    />
                ) : null
            }
            {
              activeMiners && Object.keys(activeMiners).length > 0 ?
              <>
                <p className='Boosts-sectiontitle tracking-wider text-sm font-bold uppercase mt-6 mb-3'>
                    {t('minerRented')}
                </p>
                {
                Object.values(activeMiners).sort((a, b) => a.status != b.status ? (a.status === 'ACTIVE' ? -1 : 1) : 1).map(item => 
                   <MiningItem
                        id={item.id}
                        price={item.price}
                        total={item.total}
                        available={false}
                        progress={item.progress}
                        running={item.uid in timeLeftObj ? timeLeftObj[item.uid] : undefined}
                        status={item.status}
                        onOpenEvent={() => {}}
                    />
                )
            }
              </> : null
            }
        </Panel>
    );
};

export default Mining;