import { t } from "i18next";
import React, {FC, useEffect, useMemo} from "react";
import WoodRocket from "../../../../assets/icons/currency/rocket/Wood";
import { formatNumberShort, formatNumberWithSpaces, formatNumberWithSpacesShort } from "../../../../utils/mathUtils";
import OverflowIcon from '../../../../components/OverflowIcon/OverflowIcon';
import CrystalBase from "../../../../assets/icons/currency/crystal/Base";
import CrystalShadow from "../../../../assets/icons/currency/crystal/Shadow";
import Icon24ChevronRight from "../../../../assets/icons/Icon24ChevronRight";
import { useSelector } from "react-redux";
import { DefaultStateType } from "../../../../store/reducer";
import Icon24CBC from "../../../../assets/icons/Icon24CBC";
import Icon24Check from "../../../../assets/icons/Icon24Check";

export interface MiningItemProps {
  id: string
  price: number
  total: number
  available: boolean
  progress?: number
  running?: string
  status?: string
  onOpenEvent: React.MouseEventHandler
}

const MiningItem: FC<MiningItemProps> = ({id, price, total, available,  progress = null, running = null, status = null, onOpenEvent}) => {

  const goldPerHour = useSelector((s: DefaultStateType) => s.goldPerHour)

  return (
    <div 
      className="MiningItem--container [&:not(:last-child)]:mb-2 flex w-full py-2 pl-3 pr-4 bg-[#16172F] items-center gap-3 rounded-2xl"
      onClick={onOpenEvent}
    >
      <img width={60} height={60} className="MiningItem--image object-contain h-full rounded-lg"
        src={require(`../../../../assets/images/mining/${id}.png`)} alt=""
      />
      <div className="mf--info flex w-full flex-col gap-1">
        <p className="font-base text-base text-white">
          {t(`minerName--${id}`)}
        </p>
        {
            <div className="MiningItem--coins flex gap-1.5 items-center w-full">
              <div className="w-1/2 flex flex-col gap-1">
                <p className="text-xs text-[var(--secondary\_hint)] font-black text-nowrap">
                  {progress !== null ? t('miningFarmed') : t('miningYouGet')}
                </p>
                <div className="flex flex-row w-fit items-center h-5">
                  <p className="MiningItem--icon font-extrabold text-xs/4">
                    {(progress !== null ? formatNumberWithSpacesShort(Number(progress)) : formatNumberWithSpaces(Math.ceil(total))) + ' CBC'}
                  </p>
                </div>
              </div>
              {
                !status || status === 'ACTIVE' ?
                  <div className="BuildingItemBox--full border-l-1 border-slate-700/[.85] pl-4 h-9 flex flex-col"/>
                  :null
              }
              <div className="w-1/2 flex flex-col gap-1">
                {
                  !progress && !running ? 
                    <>
                      <p className="text-xs text-[var(--secondary\_hint)] font-black">
                        {t('boostsPrice')}
                      </p>
                      <div className="flex flex-row w-fit items-center">
                        <OverflowIcon 
                          Base={<CrystalBase width='12px' height='20px' />}
                          Shadow={<CrystalShadow width='26px' height='23px' />}
                          shadowOffsetTop={11}
                          shadowOffsetLeft={-7}
                          width={12}
                          height={20}
                        />
                        <p className={[
                          "MiningItem--icon font-extrabold text-xs/4 ml-1",
                          !available ? 'text-red-500': ''
                        ].join(' ')}>
                          {formatNumberWithSpaces(Math.ceil(price))}
                        </p>
                      </div>
                    </> : 
                    (status === 'ACTIVE' ? 
                      <>
                      <p className="text-xs text-[var(--secondary\_hint)] font-black">
                        {t('miningTimeLeft')}
                      </p>
                      <div className="flex flex-row w-fit items-center">
                        <div className="bg-[#4F3CC8] px-1.5 text-sm font-semibold rounded-xl">
                          {running}
                        </div>
                      </div>
                    </> : null)
                }
              </div>
            </div>
          }
      </div>
      <div className="MiningItem--crystals flex w-fit gap-1.5 min-w-6">
        {
          !running && !status ? <Icon24ChevronRight /> : (
            status === 'INACTIVE' ? <Icon24Check /> : null
          )
        }
      </div>
    </div>
  )
}

export default MiningItem;