export const ROUTE_HOME = '/';
export const ROUTE_BUILDINGS = '/buildings';
export const ROUTE_FRIENDS = '/friends';
export const ROUTE_FRIENDS_BONUS = '/friendsBonus';
export const ROUTE_TASKS = '/tasks';
export const ROUTE_BOOSTS = '/boosts';
export const ROUTE_MINING = '/mining';
export const ROUTE_STATISTICS = '/statistics';
export const ROUTE_BALANCE = '/balance';
export const ROUTE_ACHIEVEMENTS = '/achievements';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_LEAGUE = '/league';
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_PRIVATE_SALE = '/private';
export const ROUTE_PUBLIC_SALE = '/public';
export const ROUTE_GIVEAWAY = '/give'
export const ROUTE_PREMIUM = '/premium'
export const ROUTE_LOOT = '/lootbox'


export const MODAL_BOOST_BUY_CONFIRM = 'MODAL_BOOST_BUY_CONFIRM';
export const MODAL_TASK_CHANNEL = 'MODAL_TASK_CHANNEL';
export const MODAL_ADMIN_TASK = 'MODAL_ADMIN_TASK';
export const MODAL_OFFLINE_MINING = 'MODAL_OFFLINE_MINING';
export const MODAL_LEVEL_UP = 'MODAL_LEVEL_UP';
export const MODAL_DAILY_TASK = 'MODAL_DAILY_TASK'
export const MODAL_DEFAULT_TASK = 'MODAL_DEFAULT_TASK'
export const MODAL_BANK = 'MODAL_BANK'
export const MODAL_ACHIEVEMENT = 'MODAL_ACHIEVEMENT'
export const MODAL_AD = 'MODAL_AD'

export const MODAL_BOOST_PREMIUM = 'MODAL_BOOST_PREMIUM'
export const MODAL_BOOST_NORMAL = 'MODAL_BOOST_NORMAL'
export const MODAL_MINER = 'MODAL_MINER'
export const MODAL_BUY_BUILDINGS = 'MODAL_BUY_BUILDINGS'
export const MODAL_DONATE = 'MODAL_DONATE'
export const MODAL_EXCHANGE = 'MODAL_EXCHANGE'
export const MODAL_BALANCE = 'MODAL_BALANCE'
export const MODAL_WITHDRAW = 'MODAL_WITHDRAW'
export const MODAL_BUY_CRYSTALS = 'MODAL_BUY_CRYSTALS'
export const MODAL_BUY_CBC = 'MODAL_BUY_CBC'

export const SNACKBAR_ACHIEVEMENT = 'SNACKBAR_ACHIEVEMENT'
export const SNACKBAR_ERROR = "SNACKBAR_ERROR"